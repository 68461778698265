import React from "react"
import FeaturedPodcasts from "./FeaturedPodcasts"
import AllPodcasts from "./AllPodcasts"
import Query from "../Query"
import { QUERY_ARTIST_BY_ID_NAME_ONLY } from "../../queries/page/artist"
import * as St from "../Styled"

const ContentFeaturedPodcasts = props => {
  return (
    <Query
      query={QUERY_ARTIST_BY_ID_NAME_ONLY}
      variables={{
        id: props.artistID,
      }}
      fetchPolicy="network-only"
    >
      {({ data: { artist } }) => {
        return (
          <St.ContentParent>
            <St.AdWrapper>
              <St.LeftAdSection />
              <St.LeftAd2Section />
            </St.AdWrapper>
            <St.ContentWrapper>
              <FeaturedPodcasts artist={artist} />
              <St.CenterContentSection>
                <AllPodcasts artist={artist} />
              </St.CenterContentSection>
              <St.BottomAdSection />
            </St.ContentWrapper>
            <St.AdWrapper>
              <St.RightAdSection />
              <St.RightAd2Section />
            </St.AdWrapper>
          </St.ContentParent>
        )
      }}
    </Query>
  )
}

export default ContentFeaturedPodcasts
