import React, { useContext } from "react"
import StyledImage from "../../StyledImage"
import ReadMorePara from "../../ReadMorePara"
import moment from "moment-timezone"
import {
  MEDIA_TYPE_AUDIO,
  getConfigValue,
  convertToSlug,
  DATA_TYPE_ARTIST_PODCAST,
} from "../../../constants/props"
import { artistPodcastsShareURL } from "../../../constants/share"
import * as St from "../../Styled"
import { AppContext } from "../../../contexts/AppContext"
import LikeAndShare from "../../LikeAndShare"
import useRecordUserData from "../../../hooks/useRecordUserData"

const PodcastCard = ({ podcast }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { updatePlayer, device, userState, ctxPlayer } = useContext(AppContext)
  const {
    id,
    uri,
    title,
    updatedAt,
    presenter,
    show,
    about,
    artist,
    viewed,
    premium,
  } = podcast

  const recordUserData = useRecordUserData({
    type: DATA_TYPE_ARTIST_PODCAST,
    id: podcast ? podcast.id : null,
    user: userState ? userState.id : null,
    viewed: viewed != null ? viewed : 0,
  })

  if (!podcast) return null

  const image =
    podcast.image && podcast.image.url
      ? podcast.image.url.startsWith("http")
        ? podcast.image.url
        : basePrefix + podcast.image.url
      : ""
  const isPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_ARTIST_PODCAST &&
    ctxPlayer.playing
      ? true
      : false

  return (
    <St.PodcastListWrapper>
      <St.PodcastWrapper>
        <St.PodcastListImageWrapper>
          <StyledImage
            image={image}
            width={"100%"}
            height={"100%"}
            shaded={true}
            playing={isPlaying}
            premium={premium}
            imageAlt={title}
            borderRadius={
              getConfigValue(device, "podcasts.list.image.borderRadius") + "px"
            }
            onClickPlay={event => {
              event.preventDefault()
              event.stopPropagation()

              if (!isPlaying) {
                updatePlayer({
                  type: MEDIA_TYPE_AUDIO,
                  id,
                  title,
                  uri,
                  preview:
                    podcast.image && podcast.image.url
                      ? podcast.image.url.startsWith("http")
                        ? podcast.image.url
                        : basePrefix + podcast.image.url
                      : "",
                  playlist: [],
                  dataType: DATA_TYPE_ARTIST_PODCAST,
                  playing: true,
                  artist: {
                    id: artist ? artist.id : null,
                    slug: artist ? convertToSlug(artist.title) : null,
                  },
                  station: null,
                })

                if (userState && userState.isLoggedIn) {
                  recordUserData()
                }
              } else {
                updatePlayer({
                  ...ctxPlayer,
                  playing: false,
                })
              }
            }}
          />
        </St.PodcastListImageWrapper>
        <St.PodcastListRightTextSection>
          <St.PodcastInfoWrapper>
            <St.PodcastTitleWrapper>
              <St.PodcastTitle>{title}</St.PodcastTitle>
              <St.PodcastDate>
                {moment(updatedAt).format("DD MMMM YYYY")}
              </St.PodcastDate>
            </St.PodcastTitleWrapper>
            {presenter && presenter.title ? (
              <St.PodcastListSingleLineText>
                <div>Presenter: </div> <span>{presenter.title}</span>
              </St.PodcastListSingleLineText>
            ) : null}
            {show && show.title ? (
              <St.PodcastListSingleLineText>
                <div>Show: </div> <span> {show.title}</span>
              </St.PodcastListSingleLineText>
            ) : null}
            {podcast.about && (
              <St.PodcastListMultiLineText>
                <div>About:</div>
                <ReadMorePara
                  string={about}
                  offset={getConfigValue(device, "podcasts.list.textOffset")}
                />
              </St.PodcastListMultiLineText>
            )}
          </St.PodcastInfoWrapper>
          <St.PodcastLikeShareSection>
            <LikeAndShare
              shareURL={artistPodcastsShareURL(
                artist.id,
                convertToSlug(title),
                convertToSlug(artist ? artist.title : ""),
                artist && artist.country
                  ? convertToSlug(artist.country.title)
                  : "",
                image
              )}
              id={podcast.id}
              shareID={"ArtistAllPodcast_" + podcast.id}
              likeType={DATA_TYPE_ARTIST_PODCAST}
            />
          </St.PodcastLikeShareSection>
        </St.PodcastListRightTextSection>
      </St.PodcastWrapper>
    </St.PodcastListWrapper>
  )
}

export default PodcastCard
