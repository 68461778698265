import React, { useContext } from "react"
import Query from "../../Query"
import { QUERY_FEATURED_PODCASTS_BY_ARTIST_ID } from "../../../queries/page/podcast"
import LikeAndShare from "../../LikeAndShare"
import StyledImage from "../../../components/StyledImage"
import ReadMorePara from "../../../components/ReadMorePara"

import {
  MEDIA_TYPE_AUDIO,
  getConfigValue,
  convertToSlug,
  DATA_TYPE_ARTIST_PODCAST,
} from "../../../constants/props"
import { artistPodcastsShareURL } from "../../../constants/share"
import { AppContext } from "../../../contexts/AppContext"
import * as St from "../../Styled"
import Carousel from "../../SliderContainer/carousel"
import useRecordUserData from "../../../hooks/useRecordUserData"

const FeaturedPodcast = ({ data }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { updatePlayer, device, userState, ctxPlayer } = useContext(AppContext)
  const { id, title, uri, artist, viewed, premium } = data
  const recordUserData = useRecordUserData({
    type: DATA_TYPE_ARTIST_PODCAST,
    id: id,
    user: userState ? userState.id : null,
    viewed: viewed != null ? viewed : 0,
  })
  const podcastImage =
    data.image && data.image.url
      ? data.image.url.startsWith("http")
        ? data.image.url
        : basePrefix + data.image.url
      : ""
  const isPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_ARTIST_PODCAST &&
    ctxPlayer.playing
      ? true
      : false

  return (
    <St.PodcastFeaturedWrapper>
      <St.PodcastFeaturedImageWrapper>
        <StyledImage
          image={podcastImage}
          width={"100%"}
          height={"100%"}
          shaded={true}
          premium={premium}
          playing={isPlaying}
          imageAlt={title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()
            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_AUDIO,
                id,
                title,
                uri,
                preview:
                  data.image && data.image.url
                    ? data.image.url.startsWith("http")
                      ? data.image.url
                      : basePrefix + data.image.url
                    : "",
                playlist: [],
                dataType: DATA_TYPE_ARTIST_PODCAST,
                playing: true,
                artist: {
                  id: artist ? artist.id : null,
                  slug: artist ? convertToSlug(artist.title) : null,
                },
                station: null,
              })
              recordUserData()
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.PodcastFeaturedImageWrapper>
      <St.PodcastFeaturedRightTextSection>
        <St.PodcastFeaturedTitle> {title} </St.PodcastFeaturedTitle>
        {data && data.presenter && (
          <St.PodcastFeaturedSingleLineText>
            <div>Presenter:</div>
            <span>{data.presenter.title}</span>
          </St.PodcastFeaturedSingleLineText>
        )}
        {data.show && data.show.title ? (
          <St.PodcastFeaturedSingleLineText>
            <div>Show:</div>
            <span>{data.show.title}</span>
          </St.PodcastFeaturedSingleLineText>
        ) : null}

        <St.PodcastFeaturedMultiLineText>
          <div>About:</div>
          <span>
            <ReadMorePara
              string={data.about}
              offset={getConfigValue(device, "box.podcasts.textOffset")}
            />
          </span>
        </St.PodcastFeaturedMultiLineText>

        <St.FeaturedSocialIconSection>
          <LikeAndShare
            shareURL={artistPodcastsShareURL(
              artist.id,
              convertToSlug(title),
              convertToSlug(artist ? artist.title : ""),
              artist && artist.country
                ? convertToSlug(artist.country.title)
                : "",
              podcastImage
            )}
            id={data.id}
            shareID={"featuredArtistPodcast_" + data.id}
            likeType={DATA_TYPE_ARTIST_PODCAST}
          />
        </St.FeaturedSocialIconSection>
      </St.PodcastFeaturedRightTextSection>
    </St.PodcastFeaturedWrapper>
  )
}

const FeaturedBox = ({ podcasts, artist }) => {
  let settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    autoplay: true,
    dots: true,
    autoplaySpeed: 2000,
    dotsClass: "carousel-dots",
  }

  return (
    <St.FeaturedBox>
      <St.FeaturedBoxContent>
        <St.FeaturedBoxHeading>
          FEATURED PODCAST : {artist.title}
        </St.FeaturedBoxHeading>
        <Carousel settings={settings}>
          {podcasts.map((podcast) => (
            <FeaturedPodcast
              key={podcast.id}
              data={{
                ...podcast,
              }}
            />
          ))}
        </Carousel>
      </St.FeaturedBoxContent>
    </St.FeaturedBox>
  )
}

// Fetches both artist basic detail (like artist name) and featured podcasts that are needed to draw featured podcast box
// and then passed that information to next component to draw
const FeaturedPodcasts = ({ artist }) => {
  return (
    <Query
      query={QUERY_FEATURED_PODCASTS_BY_ARTIST_ID}
      variables={{
        artistID: artist.id,
      }}
      fetchPolicy="network-only"
    >
      {({ data: { podcasts }, fetchMore }) => {
        return podcasts && podcasts.length > 0 ? (
          <FeaturedBox artist={artist} podcasts={podcasts}></FeaturedBox>
        ) : null
      }}
    </Query>
  )
}

export default FeaturedPodcasts
