import React from "react"
import ContentArtistPodcasts from "../components/ContentArtistPodcasts"
import queryString from "query-string"

const Podcasts = ({ location }) => {
  const queryParams =
    location && location.search ? queryString.parse(location.search) : {}
  return (
    <React.Fragment>
      <ContentArtistPodcasts
        artistID={queryParams.__aid}
        artistName={queryParams.a}
      />
    </React.Fragment>
  )
}

export default Podcasts
